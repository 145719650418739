import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { compose } from 'recompose';
import logo from '../../img/sitvr_logo.png';
import '../../index.css';
import { SignUpLink } from '../SignUp';
import { PasswordForgetLink } from '../PasswordForget';
import { withFirebase } from '../Firebase';
import * as ROUTES from '../../constants/routes';

const SignInPage = () => (
  <div className="containerFlex">
    <img src={logo} alt="Logo" width="121" height="68"/>
    <div className="Formbox">
    <SignInForm />
    
    </div>
    <div className="extralinks">
    <PasswordForgetLink />
    <SignUpLink />
    </div>
  </div>
);

const INITIAL_STATE = {
  email: '',
  password: '',
  error: null,
};

class SignInFormBase extends Component {
  constructor(props) {
    super(props);

    this.state = { ...INITIAL_STATE, verificaEmail:false, enviado:false };
  }
  enviaVerificacion = () => {
    this.props.firebase.doSendEmailVerification()
    .then(() => {
      this.setState({enviado:true})
    })
  }
  
  onSubmit = event => {
    const { email, password } = this.state;

    this.props.firebase
      .doSignInWithEmailAndPassword(email, password)
      .then(res => {
        if(!res.user.emailVerified){
          this.setState({verificaEmail:true})
        }else{
        this.setState({ ...INITIAL_STATE, verificaEmail:false, enviado:false });
        this.props.history.push(ROUTES.HOME);
        }
      })
      .catch(error => {
        this.setState({ error });
      });

    event.preventDefault();
  };

  onChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  };

  render() {
    const { email, password, error } = this.state;

    const isInvalid = password === '' || email === '';
    if(!this.state.verificaEmail){
      return (
        <div>
        <h4>Bienvenido</h4>
        <h6>Inicia sesión para comenzar</h6>
        <form onSubmit={this.onSubmit} className="InnerForm">
          <input
            name="email"
            value={email}
            onChange={this.onChange}
            type="text"
            placeholder="Email"
          />
          <input
            name="password"
            value={password}
            onChange={this.onChange}
            type="password"
            placeholder="Contraseña"
          />
          <button disabled={isInvalid} type="submit" className="btn btn-primary btn-sm">
            Entrar
          </button>
  
          {error && <p>{error.message}</p>}
        </form>
        </div>
      );
    }
    else{
      return(
        <div>
        <h4>Email no verificado</h4>
        <p>¿Deseas enviar un nuevo mensaje de verificación a tu email?</p>
        {this.state.enviado ? <>
          <p>Email de verificación enviado</p>
          <button className="btn btn-success btn-sm" onClick={() => this.setState({ ...INITIAL_STATE, verificaEmail:false, enviado:false })}>Iniciar Sesión</button>
        </> : <>
        <button className="btn btn-primary btn-sm" onClick={() => this.enviaVerificacion()}>Enviar verificación</button>
        </>
        }
        </div>
      )
    }
  }
}

const SignInForm = compose(
  withRouter,
  withFirebase,
)(SignInFormBase);

export default SignInPage;

export { SignInForm };