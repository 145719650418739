import React, { Component } from 'react';
import {
  BrowserRouter as Router,
  Route,
} from 'react-router-dom';
import Navigation from '../Navigation';
import SignUpPage from '../SignUp';
import SignInPage from '../SignIn';
import HomePage from '../Home';
import PasswordForgetPage from '../PasswordForget';

import * as ROUTES from '../../constants/routes';
import { withAuthentication } from '../Session';

import logo from '../../img/sitvr_logo.png';


class App extends Component{
  constructor() {
    super();
    this.state = {
      width: window.innerWidth,
    };
  }
  componentWillMount() {
    window.addEventListener('resize', this.handleWindowSizeChange);
  }
  
  // make sure to remove the listener
  // when the component is not mounted anymore
  componentWillUnmount() {
    window.removeEventListener('resize', this.handleWindowSizeChange);
  }
  
  handleWindowSizeChange = () => {
    this.setState({ width: window.innerWidth });
  };
  render(){
    const { width } = this.state;
    const isMobile = width <= 768;
    if(!isMobile){
    return(
      <Router>
      <Navigation />

        <Route exact path={ROUTES.SIGN_IN} component={SignInPage} />
        <Route path={ROUTES.SIGN_UP} component={SignUpPage} />
        <Route path={ROUTES.HOME} component={HomePage} />
        <Route path={ROUTES.PASSWORD_FORGET} component={PasswordForgetPage} />

       </Router>
    )
    }else{
      return(
          <div style={{width:'100%',textAlign:'center'}}>
            <img src={logo} alt="Logo" width="121" height="68"/>
            <p style={{color:"#fff",paddingTop:20}}>Descarga las app</p>
          </div>
        )
    }
  }
 
}

export default withAuthentication(App);