import React from 'react';
import PropTypes from 'prop-types';
import YoutubeKey from '../../youtubeAPI.js'
var container ;
class VideoItem extends React.Component{
    state = {
        videoLength : "00:00"
    }
    componentDidMount(){
      this.setState({videoLength: this.props.duration});
    }
    componentDidUpdate(){
      container = document.getElementsByClassName("App");
    }
    text_truncate = (str, length, ending) =>{
        if (length == null) {
          length = 100;
        }
        if (ending == null) {
          ending = '...';
        }
        if (str.length > length) {
            return str.replace(`/^(.{${length}}[^\s]*).*/`, "$1") + ending;
          //return str.substring(0, length - ending.length) + ending;
        } else {
          return str;
        }
      };
    
    doScroll = () => {
      window.setTimeout(function() {
      container[0].scrollTo({
          top: 0,
          behavior: 'smooth',
        })
      }, 200);
    }
    render(){
        return(
            <div className="VideoItem col-md-3 col-sm-4">
            <a href={this.props.link} id={this.props.videoID} onClick={(e) => {this.props.handleVideo(e,this.props.videoID,this.props.title);this.doScroll()}}>
                <div className="VideoBox">
                    <i className="PlayIcon far fa-play-circle"></i>
                    <img src={this.props.image} alt="" className="VideoImage"/>
                    <div className="duration">{this.state.videoLength}</div>
                </div>
                <div className="title">{this.text_truncate(this.props.title,60)}</div>
            </a>
        </div>
        )
    }
}
VideoItem.propTypes = {
    image: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
}
export default VideoItem;