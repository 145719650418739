import React from 'react';

function UserHeader(props){
    return(
    <div className="userHeader">
        <i className="fas fa-bars" onClick={(e) => props.ShowSidebar(e)}></i>
        <div>SIT VR WEB</div>
    </div>
    )
}
export default UserHeader;