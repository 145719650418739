import React, { Component } from "react";
import logo from '../../../img/sitvr_logo.png';
import YoutubeKey, {ChannelID} from '../../youtubeAPI.js';
import style from './SidebarMenu.css';
import {withFirebase} from '../../Firebase'
const chevron = <i className="fas fa-chevron-right"></i>;
class SideBarMenu extends Component{
    state = {
        playlistItems: [],
        activeClasses: []
    }
    componentDidMount(){
        this.getPlaylist();
    }
    getPlaylist = () =>{
        this.props.firebase.categories().on('value', snapshot => {
            const categoriesObj = snapshot.val(); 
            const categoriesList = Object.keys(categoriesObj).map(key => ({                
                ...categoriesObj[key],
                _key: key,
              }));
            this.setState({
                playlistItems: categoriesList,
              });
        })
    }
    render(){
        return(
            <div>
                <div className="Logo">
                    <img src={logo} alt="Logo" width="121" height="68"/>
                </div>
                <div className="navigation">
                    <ul>
                    {
                        this.state.playlistItems.map((item,index) => {
                            return <li key={item._key}><a onClick={(e)=>this.props.handleClick(e,item._key,item.Name,item.WebPhoto,index)} href={item._key} target="_blank" rel="noopener noreferrer" className={index===this.props.SideBarIndex ? "active" : ""}>{item.Name}{this.state.activeClasses[index]===this.props.SideBarIndex ? chevron : null}</a></li>
                        })
                    }
                    </ul>
                </div>
            </div>
        )
    }
}
export default withFirebase(SideBarMenu);