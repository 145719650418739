import React, {Component} from 'react';
import style from './VideoFrame.css';
import ReactResizeDetector from 'react-resize-detector';
class VideoFrame extends  Component {
    constructor(props){
        super(props);
        this.FrameVideo = React.createRef();
        this.state={
            widthVideo:0
        }
    }
    getVideoData = () => {
        
    }
    onResize(width){
        this.setState({widthVideo:width});
    }
    render(){
        return(
            <div id="VideoFrame" ref={this.FrameVideo}>
            <div className="row no-gutters">
                <div className="VideoTitle col-12">
                    <h4>{this.props.playlistTitle}</h4>
                    <h6>{this.props.videoTitle}</h6>
                </div>
                <div className="col-12" style={{lineHeight:0}}>
                <ReactResizeDetector handleWidth handleHeight onResize={width=>this.onResize(width)} />
                <iframe src={`https://player.vimeo.com/video/${this.props.videoID}`} width={this.state.widthVideo} height="600" frameborder="0" allow="autoplay; fullscreen; gyroscope; accelerometer" allowfullscreen></iframe>
            </div>
            </div>
            </div>
        )
    }
}
export default VideoFrame;