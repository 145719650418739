import React from 'react';
import Sidebar from "react-sidebar";
import SideBarMenu from '../sidebar/container/SidebarMenu';
import CategoryHeader from '../header/container/CategoryHeader';
import VideoContainer from '../videolist/container/VideoContainer';
import VideoFrame from '../videoFrame/container/VideoFrame';
import YoutubeKey, {ChannelID} from '../youtubeAPI';
import UserHeader from '../header/presentational/userHeader';
import { css } from '@emotion/core';
import { GridLoader } from 'react-spinners';
import { withAuthorization } from '../Session';
import SignOutButton from '../SignOut';
import style from './home.css';
const mql = window.matchMedia(`(min-width: 800px)`);
const override = css`
    display: block;
    margin: 0 auto;
    border-color: red;
`;


class HomePage extends React.Component{
  state = {
    sidebarDocked: mql.matches,
    sidebarOpen: false,
    playlistID:"",
    videoID:"",
    playlistName:"",
    videoTitle:"",
    sidebarIndex:1,
  }

  componentWillMount() {
    mql.addListener(this.mediaQueryChanged);
    this.getInitialPlaylist();
  }
 
  // componentWillUnmount() {
  //   this.state.mql.removeListener(this.mediaQueryChanged);
  // }
 
  onSetSidebarOpen = (e,open) => {
    this.setState({ sidebarOpen: open });
    e.preventDefault();
  }
 
  mediaQueryChanged= () => {
    this.setState({ sidebarDocked: mql.matches, sidebarOpen: false });
  }
  getNewPlaylist = (event,newplaylistID,newPlaylistName,newPlaylistPhoto,index) => {
    this.setState({
      playlistID:newplaylistID,
      playlistName:newPlaylistName,
      playlistPhoto:newPlaylistPhoto,
      videoID:"",
      sidebarIndex:index
    })
    event.preventDefault();
  }
  getInitialPlaylist = () =>{
    this.props.firebase.categories().on('value', snapshot => {
      const categoriesObj = snapshot.val(); 
      const categoriesList = Object.keys(categoriesObj).map(key => ({                
          ...categoriesObj[key],
          _key: key,
        }));
      this.setState({
          playlistItems: categoriesList,
          playlistID:categoriesList[0]._key,
          playlistName:categoriesList[0].Name,
          playlistPhoto:categoriesList[0].WebPhoto,
          videoID:"",
          sidebarIndex:0
        });
  })
    
  }
  showVideo = (event,videoID,videoTitle) => {
    this.setState({
      videoID:videoID,
      videoTitle:videoTitle
    });
    event.preventDefault();
  }
 
  render() {
    if(this.state.playlistID){
    return (
      <Sidebar
        sidebar={<SideBarMenu handleClick={(event,playlistID,playlistName,index) => this.getNewPlaylist(event,playlistID,playlistName,index)} SideBarIndex={this.state.sidebarIndex}></SideBarMenu>}
        open={this.state.sidebarOpen}
        docked={this.state.sidebarDocked}
        onSetOpen={this.onSetSidebarOpen}
        sidebarClassName="SideBar"
        contentClassName="App"
      > 
      {
        !this.state.sidebarDocked ? <UserHeader ShowSidebar={(e) => this.onSetSidebarOpen(e,true)}/> : ""
      }
      <SignOutButton />
        <div className="container-fluid AppVideos">
        
          {
            this.state.videoID ? <VideoFrame videoID={this.state.videoID} playlistTitle={this.state.playlistName} videoTitle={this.state.videoTitle}/> : <CategoryHeader playListID={this.state.playlistID} title={this.state.playlistName} photo={this.state.playlistPhoto}/>
          }
          { <VideoContainer handleVideo={(event,response,videoTitle) => this.showVideo(event,response,videoTitle)} playListID={this.state.playlistID}/>}
        </div>
       
      </Sidebar>
    );
    }else{
      return(
        <div className='sweet-loading'>
        <GridLoader
          css={override}
          sizeUnit={"px"}
          size={20}
          color={'#ffffff'}
        />
      </div> 
      )
    }
  }
}

const condition = authUser => !!authUser;

export default withAuthorization(condition)(HomePage);