import React, { Component } from 'react';
import { Link,withRouter } from 'react-router-dom';
import { compose } from 'recompose';
import { withFirebase } from '../Firebase';
import logo from '../../img/sitvr_logo.png';
import * as ROUTES from '../../constants/routes';

const PasswordForgetPage = () => (
  <div className="containerFlex">
  <img src={logo} alt="Logo" width="121" height="68"/>
    <div className="Formbox">
      <PasswordForgetForm />
    </div>
    <div className="extralinks">
    <Link to={ROUTES.SIGN_IN} className="linkdos linkbtn"><i className="fas fa-chevron-circle-left"></i> Volver</Link>
    </div>
  </div>
);

const INITIAL_STATE = {
  email: '',
  error: null,
};

class PasswordForgetFormBase extends Component {
  constructor(props) {
    super(props);

    this.state = { ...INITIAL_STATE };
  }

  onSubmit = event => {
    const { email } = this.state;

    this.props.firebase
      .doPasswordReset(email)
      .then(() => {
        this.setState({ ...INITIAL_STATE });
        this.props.history.push(ROUTES.SIGN_IN);
      })
      .catch(error => {
        this.setState({ error });
      });

    event.preventDefault();
  };

  onChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  };

  render() {
    const { email, error } = this.state;

    const isInvalid = email === '';

    return (
      <div>
        <h4>Resetar mi contraseña</h4>
        <p></p>
      <form onSubmit={this.onSubmit}  className="InnerForm">
        <input
          name="email"
          value={this.state.email}
          onChange={this.onChange}
          type="text"
          placeholder="Email"
        />
        <button disabled={isInvalid} type="submit" className="btn btn-primary btn-sm">
          Enviar
        </button>

        {error && <p>{error.message}</p>}
      </form>
      </div>
    );
  }
}

const PasswordForgetLink = () => (
    <Link to={ROUTES.PASSWORD_FORGET} className="linkuno linkbtn">He olvidado mi contraseña</Link>
);

export default PasswordForgetPage;

const PasswordForgetForm = compose(
  withRouter,
  withFirebase,
)(PasswordForgetFormBase);

export { PasswordForgetForm, PasswordForgetLink };