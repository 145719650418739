import React from 'react';

import { withFirebase } from '../Firebase';

const SignOutButton = ({ firebase }) => (
  <button type="button" onClick={firebase.doSignOut} className="btn btn-outline-secondary btn-sm btn-signout">
    <i className="fas fa-sign-out-alt"></i> Cerrar Sesión
  </button>
);

export default withFirebase(SignOutButton);