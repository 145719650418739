import React from "react";
import VideoItem from '../presentational/VideoItem';
import PropTypes from "prop-types";
import styles from './VideoContainer.css';
import YoutubeKey from '../../youtubeAPI.js';
import {withFirebase} from '../../Firebase'
let dataitems= [];
var elem , vid;
class VideoContainer extends React.Component {
    state = {
        dataVideos:[],
        nextPageToken:""
    }
    componentDidMount(){
        this.getVideos(this.props.playListID);
    }
    componentDidUpdate(){
        elem = document.getElementsByClassName("App");
        vid = document.getElementById("VideoList");
    }
    componentWillReceiveProps(nextProps){
        if(this.props.playListID!==nextProps.playListID){
            dataitems=[];
            this.setState({dataVideos:[]});
          this.getVideos(nextProps.playListID);
        }
    }
    getVideos = (playListID,nextPage) => {
        this.props.firebase.subcategories(playListID).on('value', snapshot => {
            const categoriesObj = snapshot.val(); 
            const categoriesList = Object.keys(categoriesObj).map(key => ({                
                ...categoriesObj[key],
                _key: key,
              }));
            this.setState({
                dataVideos: categoriesList,
              });
        })
    }
    doScroll = () => {
        let toScroll = vid.lastChild.offsetTop;
        window.setTimeout(function() {
        elem[0].scrollTo({
            top: toScroll-50,
            behavior: 'smooth',
          })
        }, 200);
    }
    render(){
        let button;
        if(this.state.nextPageToken!=="") {
            button = <button type="button" className="btn btn-outline-secondary" onClick={() => this.getVideos(this.props.playListID,this.state.nextPageToken)}>Ver más</button>
        }
        
        return(
            <div>
            <div className="row" id="VideoList">
            {
            this.state.dataVideos.map((item) => {
              return <VideoItem handleVideo={this.props.handleVideo} key={item._key} title={item.Name} image={item.Photo} videoID={item.VimeoURL} duration={item.Duration}/>
            })
          }
          </div>
          <div className="row justify-content-center" style={{marginBottom:40}}>
          {button}
          </div>
          </div>
        )
    }
}
VideoContainer.propTypes = {
    playListID: PropTypes.string.isRequired,
}
export default withFirebase(VideoContainer);