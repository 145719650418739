import React from 'react';

function CategoryLayout(props){
    return (
    <div className="row no-gutters">
                <div className="CategoryHeader col-12">
                    <div className="title">
                        <h2>{props.title}</h2>
                        <h5>{props.totalVideos} Videos</h5>
                    </div>
                    <div style={{backgroundImage: `url(${props.photo})`}} className="backgroundHeader"></div>
                </div>
            </div>
    )
}
export default CategoryLayout;