import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { withFirebase } from '../Firebase';
import { compose } from 'recompose';
import logo from '../../img/sitvr_logo.png';
import * as ROUTES from '../../constants/routes';

const SignUpPage = () => (
  <div className="containerFlex">
    <img src={logo} alt="Logo" width="121" height="68"/>
    <div className="Formbox">
    <SignUpForm />
    </div>
    <div className="extralinks">
    <Link to={ROUTES.SIGN_IN} className="linkdos linkbtn"><i className="fas fa-chevron-circle-left"></i> Volver</Link>
    </div>
  </div>
);
const INITIAL_STATE = {
  email: '',
  passwordOne: '',
  passwordTwo: '',
  error: null,
};
class SignUpFormBase extends Component {
  constructor(props) {
    super(props);
    this.state = { ...INITIAL_STATE, isRegistrado:false };
  }
  validateEmail(email) {
    var re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
  }
  emailDomainCheck(email)
    {
        var parts = email.split('@');
        if (parts.length === 2) {
            if (parts[1] === 'smartco.com.ec'||parts[1]==='imateo.com'||parts[1]==='udla.edu.ec'||parts[1]==='sit.health'||parts[1]==='sit.com.ec'||parts[1]==='odontocenter.ec'||parts[1]==='formazioec.com') {
                return true;
            }
        }
        return false;
    }
  onSubmit = event => {
    const { email, passwordOne } = this.state;
    
    if(this.validateEmail(email)){
      if(this.emailDomainCheck(email)){
        this.props.firebase
        .doCreateUserWithEmailAndPassword(email, passwordOne)
        .then(() => {
          return this.props.firebase.doSendEmailVerification();
        })
        .then(()  => {
          this.setState({...INITIAL_STATE, isRegistrado:true});
        })
        // .then(authUser => {
        //   this.setState({ ...INITIAL_STATE });
        //   this.props.history.push(ROUTES.HOME);
        // })
        .catch(error => {
          var errorCode = error.code;
          if(errorCode == 'auth/email-already-in-use'){
            this.setState({ error:{message: 'El Email proporcionado ya está\nregistrado'} });
          }
          if(errorCode == 'auth/weak-password'){
            this.setState({ error:{message: 'La contraseña proporcionada no es\nlo suficientemente fuerte.'} });
          }
        });
      }
      else{
        this.setState({ error:{message: 'No tienes acceso a este contenido, Para consultar nuestras soluciones y obtener información por favor contáctanos a contacto@sit.health'} });
      }
    }
    else{
      this.setState({ error:{message: 'Ingresa un email válido'} });
    }
    event.preventDefault();
  }

  onChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  };
  htmlDecode(input){
    var e = document.createElement('div');
    e.innerHTML = input;
    return e.childNodes.length === 0 ? "" : e.childNodes[0].nodeValue;
  }
  render() {
    const {
      email,
      passwordOne,
      passwordTwo,
      error,
    } = this.state;

    const isInvalid =
      passwordOne !== passwordTwo ||
      passwordOne === '' ||
      email === '';
      if(!this.state.isRegistrado){
        return (
          <div>
            <h4>Crea una cuenta</h4>
            <p></p>
            <form onSubmit={this.onSubmit} className="InnerForm">
            <input
              name="email"
              value={email}
              onChange={this.onChange}
              type="text"
              placeholder="Email"
            />
            <input
              name="passwordOne"
              value={passwordOne}
              onChange={this.onChange}
              type="password"
              placeholder="Contraseña"
            />
            <input
              name="passwordTwo"
              value={passwordTwo}
              onChange={this.onChange}
              type="password"
              placeholder="Repita la contraseña"
            />
            <button disabled={isInvalid} type="submit" className="btn btn-primary btn-sm">
              Crear cuenta
            </button>
    
            {error && <p>{this.htmlDecode(error.message)}</p>}
          </form>
          </div>
        );
      }
      else{
        return(
          <div>
            <h4>Registro enviado</h4>
            <p>Tu cuenta ha sido creada con éxito.<br></br>Revisa tu correo y activa tu cuenta.</p>
          </div>
        )
      }
  }
}

const SignUpLink = () => (
 <Link to={ROUTES.SIGN_UP} className="linkdos linkbtn">Crear una cuenta</Link>
);
const SignUpForm = compose(
  withRouter,
  withFirebase,
)(SignUpFormBase);

export default SignUpPage;

export { SignUpForm, SignUpLink };