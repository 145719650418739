import React from "react";
import PropTypes from "prop-types";
import CategoryLayout from '../presentational/CategoryLayout'
import style from './CategoryHeader.css';
import YoutubeKey from '../../youtubeAPI'
import {withFirebase} from '../../Firebase'
class CategoryHeader extends React.Component{
    state = {
        playlistTitle : "",
        totalVideos:"",
        photoPlaylist:""
    }
    componentDidMount(){
        this.getVideos(this.props.playListID);
        this.setState({
          photoPlaylist:this.props.photo,
          playlistTitle:this.props.title
        })
    }
    componentWillReceiveProps(nextProps){
      if(this.props.playListID!==nextProps.playListID){
        this.getVideos(nextProps.playListID);
        this.setState({
          photoPlaylist:nextProps.photo,
          playlistTitle:nextProps.title
        })
      }
    }
    getVideos = (playListID) => {
      this.props.firebase.subcategories(playListID).on('value', snapshot => {
        const categoriesObj = snapshot.val(); 
        const categoriesList = Object.keys(categoriesObj).map(key => ({                
            ...categoriesObj[key],
            _key: key,
          }));
        this.setState({
            totalVideos: categoriesList.length,
          });
          })
        }
      getPlaylistData = (playListID) => {
        const URL = `https://www.googleapis.com/youtube/v3/playlists?part=snippet&id=${playListID}&key=${YoutubeKey}`;
        console.log(URL);
        fetch(URL)
          .then(response => {
            return response.json();
          })
          .then(response => {
            this.setState({
              playlistTitle : response.items[0].snippet.title,
              photoPlaylist : response.items[0].snippet.thumbnails.default.url
            })
          });
        }
    render(){
        return(
            <CategoryLayout photo={this.state.photoPlaylist} title={this.state.playlistTitle} totalVideos={this.state.totalVideos}></CategoryLayout>
        )
    }
    
}
CategoryHeader.propTypes = {
    playListID: PropTypes.string.isRequired,
}
export default withFirebase(CategoryHeader);